@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
}

@layer components {
  .nav-link {
    @apply text-base font-semibold leading-6 text-gray-900 hover:text-indigo-500;
  }

  .nav-link.active {
    @apply text-indigo-500;
  }
}
